import { ref } from "vue";

import {useToast} from "primevue/usetoast";
import {$http} from "@/axios";

export const useHttpUtils = () => {
    const state = ref({
        loading: false
    });

    const toast = useToast();
    const toastLife = 10000;

    const getError = (error) => {
        if (error && error.response && error.response.data) {
            if (typeof error.response.data === 'object' && "messages" in error.response.data && "success" in error.response.data && "result" in error.response.data) {
                return error.response.data
            }
            else if(typeof error.response.data === 'object' ) {
                let messages = [];

                Object.keys(error.response.data).forEach(function(key) {
                    // key: the name of the object key
                    // index: the ordinal position of the key within the object
                    messages.push(key + ": " + error.response.data[key])
                });

                 return {success: false, messages: messages, result: null};
            }
            else if (Array.isArray(error.response.data)) {
                return {success: false, messages: error.response.data.join("\n"), result: null};
            }

            return {success: false, messages: [error.response.data], result: null};
        }

        return {success: false, messages:["Unknown server error"], result: null};
    }

    const getErrorMessage = (error) => {
        const response = getError(error);
        return response.messages.join("\n");
    }

    const getMessages = (response) => {
        return response && response.messages && response.messages.length > 0 ? response.messages.join(",") : "Unknown server error"
    }

    const post = async (config) => {
        if (!config) return;
        const {url, data, showSuccess} = config;
        if (!url) {
            toast.add({
                        severity: 'warn',
                        summary: 'Error',
                        detail: "Url for axios not provided",
                        life: toastLife
            });
        }

        state.value.loading = true;
        return await $http.post(url, data)
            .then(response => response.data)
            .then(response => {
                if (response && response.success) {
                    if (showSuccess) {
                        toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: "Action done successfully",
                            life: 3000}
                        )
                    }

                }
                else {
                    toast.add({
                        severity: 'warn',
                        summary: 'Error',
                        detail: getMessages(response),
                        life: toastLife}
                    )
                }
                return response;
            })
            .catch(error => {
                console.error(error);
                toast.add({
                        severity: 'warn',
                        summary: 'Error ',
                        detail: getErrorMessage(error),
                        life: toastLife}
                    )
                return getError(error);
            })
            .finally(() => (state.value.loading = false));
    }

    const get = async (config) => {
        if (!config) return;

        const {url, showSuccess, data} = config;
        state.value.loading = true;
        return await $http.get(url, {
                params: data,
            })
            .then(response => response.data)
            .then(response => {
                if (response && response.success) {
                    if (showSuccess) {
                        toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: "Action done successfully",
                            life: toastLife})
                    }
                }
                else {
                    toast.add({
                        severity: 'warn',
                        summary: 'Error',
                        detail: getMessages(response),
                        life: 3000}
                    )
                }

                return response;
            })
            .catch(error => {
                toast.add({
                        severity: 'warn',
                        summary: 'Error',
                        detail: getErrorMessage(error),
                        life: toastLife}
                    )
                return getError(error);
            })
            .finally(() => (state.value.loading = false))
    }

    return {
        state,
        get,
        post
    }
}