// import logo from "@/assets/images/logo.jpeg";
// import logoWhite from "@/assets/images/logo-white.png";


export const appStore = {
    namespaced: true,
    state() {
        return {
            logo:  null,
            logoWhite: null,
            menus: [],
            user: null,
            token: null,
            tokenType: null,
            locale: "en-US",
            tenant: null,
        }
    },
    mutations: {
        setMenus(state, data) {
            state.menus = data;
        },
        setLocale(state, data) {
            state.locale = data;
        },
        setUser(state, data) {
          state.user = data;
        },
        setToken(state, data) {
            state.token = data;
        },
        setTokenType(state, data) {
            state.tokenType = data;
        },
        setTenant(state, data) {
            state.tenant = data;
        },
        removeUser (state) {
            state.user = null;
            state.token = null;
            state.refresh = null
            state.menus = null;
        }
    },
    actions: {
        setMenus(context, data) {
            context.commit('setMenus', data);
        },
        setLocale(context, data) {
            context.commit('setLocale', data);
        },
        setTenant(context, data) {
            context.commit('setTenant', data);
        },
        removeUser(context) {
            context.commit('removeUser');
        },

        setUserInfo(context, data) {
            context.commit('setUser', data?.user)
            context.commit('setToken', data?.access_token);
            context.commit('setTokenType', data?.type);
        }
    }
}
