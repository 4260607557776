import {useHttpUtils} from "@/services/http/httpUtils";
import {useUtils} from "@/services/utils/utils";

export const useTenantService = () => {
    const {get} = useHttpUtils();
    const {baseUrl} = useUtils();

    const getTenant = async () => {
        return await get({
            url: "/api/tenant/client/detail",
        });
    }

    const getImageSrc = (tenant, attribute) => {
        return baseUrl() + '/media/' + tenant?.imageFolder + "/" + attribute
    }

    const getLogoSrc = (tenant) => {
        return getImageSrc(tenant, tenant?.logoImage);
    }

    const getLogoWhiteSrc = (tenant) => {
        return getImageSrc(tenant, tenant?.logoWhiteImage);
    }

    return {
        getTenant,
        getLogoSrc,
        getLogoWhiteSrc
    }
}