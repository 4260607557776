<template>
    <router-view/>
    <Toast position="bottom-right" >
        <template #message="slotProps">
            <div class="flex align-items-center w-full">
                <i class="pi pi-exclamation-triangle text-4xl" style="font-size: 3rem"></i>
                <div class="flex flex-column pl-3">
                    <h4 class="m-0 p-0">{{slotProps.message.summary}}</h4>
                    <p class="m-0 p-0" v-html="slotProps.message.detail"></p>
                </div>
            </div>
        </template>
    </Toast>
</template>

<script>

import {useTenantService} from "@/services/tenant/tenantService";
import {onMounted} from "vue";
import {store} from "@/store";

export default {
  name: 'App',
  components: {},
  setup() {
        const {getTenant} = useTenantService();

        onMounted(() => {
            getTenant().then(response => {
                store.dispatch("app/setTenant", response?.result);
            });
        });

        return {}
    }
}
</script>

<style>
</style>
