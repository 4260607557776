import { createApp } from 'vue'
import App from './App.vue';
import {router} from "./router";
import PrimeVue from 'primevue/config';
import {store} from "./store";
import mitt from 'mitt'

import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import Menubar from "primevue/menubar";
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import OverlayPanel from 'primevue/overlaypanel';
import Chips from 'primevue/chips';
import Chip from 'primevue/chip';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';
import FileUpload from 'primevue/fileupload';
import Message from 'primevue/message';
import Editor from 'primevue/editor';
import Divider from 'primevue/divider';
import Calendar from 'primevue/calendar';
import Tag from 'primevue/tag';
import dayjs from 'dayjs'
import Menu from 'primevue/menu';

import 'primevue/resources/primevue.min.css';
import '@/assets/css/theme-overrides.scss';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@/assets/css/custom.scss';

import {$http} from "@/axios";
import {setupI18n} from "@/i18n";

const app = createApp(App);

// i18n lazy loading
const i18n = setupI18n()
const emitter = mitt()



app.directive('tooltip', Tooltip);
app.use(router);
app.use(i18n);
app.use(store);
app.use(ToastService);
app.use(PrimeVue, {ripple: true});
app.component('MenuBar', Menubar);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Toast', Toast);
app.component('Button', Button);
app.component('Card', Card);
app.component('InputText', InputText);
app.component('OverlayPanel', OverlayPanel);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('Textarea', Textarea);
app.component('Checkbox', Checkbox);
app.component('FileUpload', FileUpload);
app.component('Message', Message);
app.component('Editor', Editor);
app.component('Calendar', Calendar);
app.component('Divider', Divider);
app.component('Tag', Tag);
app.component('Menu', Menu);
app.component('ProgressSpinner', ProgressSpinner);
app.provide('dayJS', dayjs)
app.provide('emitter', emitter);
app.config.globalProperties.$http = $http
app.mount("#app");