import {createRouter, createWebHistory} from "vue-router";
import {store} from '@/store';

const routes = [
    {path: "/login", component: () => import('./pages/login/Index'), props: true, name: "login-page"},
    {
        path: "/logout",
        component: () => import('./pages/login/Index'),
        beforeEnter: (to, from, next) => {
            store.dispatch('app/removeUser').then(() => next());
        }
    },
    {
        path: "/",
        name: "home",
        component: () => import('./pages/app/Index'),
        children: [
            {
                path: 'orders',
                components: {
                    pageContent: () => import('./pages/order/Index')
                }
            },
            {
                path: 'dashboard',
                components: {
                    pageContent: () => import('./pages/dashboard/Index')
                }
            },
            {
                path: 'items',
                components: {
                    pageContent: () => import('./pages/items/Index')
                }
            },
            {
                path: 'sliders',
                components: {
                    pageContent: () => import('./pages/sliders/Index')
                }
            },
            {
                path: 'categories',
                components: {
                    pageContent: () => import('./pages/categories/Index.vue')
                }
            },
            {
                path: 'products',
                components: {
                    pageContent: () => import('./pages/product/Index.vue')
                }
            },
            {
                path: 'menus',
                components: {
                    pageContent: () => import('./pages/menu/Index.vue')
                }
            },
            {
                path: 'metadata',
                components: {
                    pageContent: () => import('./pages/metadata/Index.vue')
                }
            },
            {
                path: 'articles',
                components: {
                    pageContent: () => import('./pages/articles/Index.vue')
                }
            },
            {
                path: 'booking',
                components: {
                    pageContent: () => import('./pages/booking/Index.vue')
                }
            }
        ],
        beforeEnter: (to, from, next) => {
            if (!store.state.app.token) {
                next({name: 'login-page'});
            } else {
                next();
            }
        }
    }
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
});