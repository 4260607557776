import axios from "axios";

import {store} from '@/store';
import {router} from "@/router";
import {useUtils} from "@/services/utils/utils";

const DEBUG = process.env.NODE_ENV === "development";

console.debug("*** Node process env ***\n", process.env);

// baseURL: process.env.VUE_APP_API_URL || 'http://localhost:8000/'
const baseAxios = axios.create({
    //baseURL: window.location.protocol + "//api." + window.location.hostname + ":" + process.env.VUE_APP_API_PORT
    baseURL: useUtils().baseUrl()
});


baseAxios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error && error.response && error.response.status === 401) {
        store.dispatch('app/removeUser').then(async () => {
            if (DEBUG) {
                console.info("✉", error);
            }

            await router.push({name: "login-page"});
        });
    }
    else if (error && error.response && error.response.status === 404) {
        error.response.data = {
            success: false,
            messages: ["Url not found: " + error.config.url + "<br>Method: " + error.config.method],
            result: null
        }
    }
    else if (error && error.response && error.response.status >= 500) {
        error.response.data = {
            success: false,
            messages: ["Server error: " + error.config.url + "<br>Method: " + error.config.method],
            result: null
        }
    }
    return Promise.reject(error);
});

baseAxios.interceptors.request.use(
    (config) => {
        let token = store.state.app.token;
        console.info("Token: ", token)
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error)
    }
);

export const $http = baseAxios;