import { parseDomain } from "parse-domain";
import {inject} from "vue";

export const useUtils = () => {
    const dayJS = inject("dayJS");

    const findEntryRoute = (menu) => {
        if (menu.to)
            return menu.to;
        else if (menu.items && menu.items.length > 0) {
            for (let i = 0; i < menu.items.length; i++) {
                return findEntryRoute(menu.items[i]);
            }
        }
    };

    const baseUrl = ()=> {
        console.debug("-- Initializing api url --");
        console.debug("Hostname: ", window.location.hostname);
        const {domain, topLevelDomains} = parseDomain(window.location.hostname);
        console.debug("Parsed info: ", domain, topLevelDomains);
        const port = process.env.VUE_APP_API_PORT;
        return window.location.protocol + "//api." +
            (domain ? domain : window.location.hostname) +
            (domain ? "." : "") +
            (topLevelDomains ? (topLevelDomains.length > 0 ? topLevelDomains[0] : "com") : "") +
            (port ? ":" + port : "")
    };

    const getValue = (value) => {
        if (value instanceof Date)
            return dayJS(value).format("DD/MM/YYYY");

        return value;
    }

    const formItemsToKeyValue = (items) => {
        if (!items) return;
        return items.map(i => {
                    return {key: i.name, value: i.label}
                });
    }

    return {
        findEntryRoute,
        getValue,
        dayJS,
        baseUrl,
        formItemsToKeyValue
    }
}