import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";

import {appStore} from "@/store/modules/app";


export const store = createStore({
    plugins: [
        createPersistedState({storage: window.sessionStorage, key: "rerodManager"})
    ],
    modules: {
        app: appStore
    }
})

